import { CourseFiltersInputs, CourseListProvider, CoursesListResponse } from '@/types/course.type';
import { ImportNotValidQuestions, ImportValidQuestions } from '@/types/exam.type';
import { Lang } from '@/types/general.type';
import {
  QuestionSaveFormat,
  QuestionsFiltersResponse,
  QuestionsListResponse,
  QuestionForm,
  QuestionDetailsResponse,
  BasicQuestionActionResponse,
  FiltersInputs,
  ImportQuestionResult,
  ImportFiltersResponse, Language, Difficulty,
  QuestionReportsResponse,
  TypeIloInput,
} from '@/types/questions.type';
import { Subject } from '@/types/subject.types';

import provider from './main.provider';
import { HeaderILos, FlexibleILOsData } from '../types/ilos.type';

class QuestionsProvider implements CourseListProvider {
  async getCourseSubjects(appId: string, courseId: string) {
    const subjectsResonse: { rights: Array<string>; subjects: Array<Subject> } = await provider.get(
      `/api/subjects/${appId}`,
      {
        params: {
          courseId,
        },
      },
    );
    return subjectsResonse.subjects;
  }

  async getAppCourseList(appId: string, query: CourseFiltersInputs): Promise<CoursesListResponse> {
    const courseFilters = { ...query, activeStatus: '1' };
    const result: CoursesListResponse = await provider.get(`/api/courses/${appId}/getList`, {
      params: courseFilters,
    });
    return result;
  }

  async getQuestionFilters(appId: string, courseId: string, query: object) {
    const result: QuestionsFiltersResponse = await provider.get(
      `/api/questions/${appId}/${courseId}/getQuestionFilters`,
      { params: query },
    );
    return result;
  }

  async getQuestionsList(appId: string, courseId: string, query: object) {
    const result: QuestionsListResponse = await provider.get(
      `/api/questions/${appId}/${courseId}/getQuestionsList`,
      { params: query },
    );

    return result;
  }

  async getQuestionDefaultValues(appId: string, courseId: string, query: object) {
    const result: QuestionForm = await provider.get(
      `/api/questions/${appId}/${courseId}/getQuestionDefaults`,
      { params: query },
    );
    return result;
  }

  async getQuestionDetails(
    appId: string,
    courseId: string,
    questionId: string,
    query: object,
    edit = false,
  ) {
    const result: QuestionDetailsResponse = await provider.get(
      `/api/questions/${appId}/${courseId}/${questionId}`,
      { params: query },
    );

    if (result.question) {
      if (!result.question.basicData?.isFlexibleILOs) {
        result.question.ilos = this.formalizeQuestionIlos(result.question.ilos, edit);
        if (result.question.subQuestions) {
          result.question.subQuestions = result.question.subQuestions.map((subQuestion) => ({
            ...subQuestion,
            ilos: this.formalizeQuestionIlos(subQuestion.ilos, edit),
          }));
        }
      }
      // else if (result.question.subQuestions) {
      //   result.question.subQuestions = result.question.subQuestions.map((subQuestion) => ({
      //     ...subQuestion,
      //     ilos: subQuestion.ilos,
      //   }));
      // }
    }

    return result;
  }

  async getQuestionVersion(appId: string, courseId: string, questionId: string, version: number | null): Promise<QuestionDetailsResponse> {
    const result: QuestionDetailsResponse = await provider.get(`/api/questions/${appId}/${courseId}/${questionId}/version/${version}`);
    if (result.question && !result.question.basicData?.isFlexibleILOs) {
      result.question.ilos = this.formalizeQuestionIlos(result.question.ilos, false);
      if (result.question.subQuestions) {
        result.question.subQuestions = result.question.subQuestions.map((subQuestion) => ({
          ...subQuestion,
          ilos: this.formalizeQuestionIlos(subQuestion.ilos, false),
        }));
      }
    }
    return result;
  }

  async getQuestionVersionReports(appId: string, courseId: string, questionId: string, version: number | null): Promise<QuestionReportsResponse> {
    const result: QuestionReportsResponse = await provider.get(`/api/questions/${appId}/${courseId}/${questionId}/version/${version}/reports`);
    return result;
  }

  async deleteQuestion(appId: string, courseId: string, questionId: string, query: object) {
    const result: BasicQuestionActionResponse = await provider.delete(
      `/api/questions/${appId}/${courseId}/${questionId}`,
      { params: query },
    );
    return result;
  }

  async deleteSelectedQuestions(appId: string, courseId: string, query: object) {
    const result: BasicQuestionActionResponse = await provider.delete(
      `/api/questions/${appId}/${courseId}/deleteQuestions`,
      { params: query },
    );
    return result;
  }

  async askForQuestionReview(appId: string, courseId: string, questionId: string, query: object) {
    const result: BasicQuestionActionResponse = await provider.put(
      `/api/questions/${appId}/${courseId}/askForReview/${questionId}`,
      {},
      { params: query },
    );
    return result;
  }

  async openQuestion(appId: string, courseId: string, questionId: string, query: object) {
    const result: BasicQuestionActionResponse = await provider.put(
      `/api/questions/${appId}/${courseId}/open/${questionId}`,
      {},
      { params: query },
    );
    return result;
  }

  async exportQuestionAPI(
    appId: string,
    filters: FiltersInputs,
    courseId: string,
    lang: string | undefined,
  ): Promise<Blob> {
    const result: Blob = await provider.get(`/api/questions/${appId}/${courseId}/questionExport`, {
      params: { ...filters, lang },
      responseType: 'blob',
    });
    return result;
  }

  async getImportFilters(courseId: string, appId: string): Promise<ImportFiltersResponse> {
    const filtersResonse: ImportFiltersResponse = await provider.get(
      `/api/questions/${appId}/${courseId}/import`,
      { params: {} },
    );

    let subjects: Array<Subject> = [];
    if (Array.isArray(filtersResonse.subjects)) {
      subjects = filtersResonse.subjects.map((item) => ({
        id: `${item.subjectID}`,
        name: item.subjectName,
      }));
    }
    let languages: Array<Language> = [];
    if (Array.isArray(filtersResonse.languages)) {
      languages = filtersResonse.languages;
    }
    const result = {
      subjects,
      languages,
      isSCU: filtersResonse.isSCU,
    };
    return result;
  }

  async importQuestion(
    appId: string,
    file: File,
    fileType: string,
    subject: string,
    courseId: string,
    language: string,
    degree: number,
    time: string,
    difficulty: number,
    ilos: HeaderILos | FlexibleILOsData[] | TypeIloInput[],
  ) {
    const reqData = new FormData();
    reqData.append('fileType', fileType);
    reqData.append('subjectId', subject);
    reqData.append('courseId', courseId);
    reqData.append('languageId', language);
    reqData.append('degree', degree.toString());

    reqData.append('time', time);

    reqData.append('difficulty', difficulty.toString());

    reqData.append('file', file);

    reqData.append('ilos', JSON.stringify(ilos));

    const result: {
      numberOfSuccess?: number;
      questions?: Array<ImportQuestionResult>;
      succededQuestions?: Array<ImportQuestionResult>;
      validQuestions?: Array<ImportValidQuestions>;
      failedQuestions?: Array<ImportNotValidQuestions>;
      difficultyLevel?: Difficulty;
    } = await provider.post(`/api/questions/${appId}/${courseId}/import`, reqData);
    return result;
  }

  formalizeQuestionIlos(ilos, edit) {
    return ilos.reduce(
      (acc, ilo) => {
        if (!acc[ilo.headerId as string]) {
          return acc;
        }
        if (edit) {
          acc[ilo.headerId as string].push({
            ...ilo,
            subIlo: ilo.subilo,
            subIloId: ilo.subilo_id,
          });
        } else {
          const iloIndex = acc[ilo.headerId as string].findIndex((c) => c.ilo_id === ilo.ilo_id);
          if (iloIndex === -1) {
            acc[ilo.headerId as string].push({
              ...ilo,
              subIlos: ilo.subilo_id
                ? [
                  {
                    subIlo: ilo.subilo,
                    subIloId: ilo.subilo_id,
                  },
                ]
                : [],
            });
          } else {
            acc[ilo.headerId as string][iloIndex].subIlos.push({
              subIlo: ilo.subilo,
              subIloId: ilo.subilo_id,
            });
          }
        }

        return acc;
      },
      {
        '1.1.': [],
        '1.2.': [],
        '1.3.': [],
        '1.4.': [],
      },
    );
  }

  async createQuestion(
    appId: string,
    courseId: string,
    reqData: QuestionSaveFormat,
    query: object,
  ): Promise<BasicQuestionActionResponse> {
    const result: BasicQuestionActionResponse = await provider.post(
      `/api/questions/${appId}/${courseId}/add`,
      reqData,
      { params: query },
    );
    return result;
  }

  async editQuestion(
    appId: string,
    courseId: string,
    questionId: string,
    reqData: QuestionSaveFormat,
    query: object,
  ): Promise<BasicQuestionActionResponse> {
    const result: BasicQuestionActionResponse = await provider.put(
      `/api/questions/${appId}/${courseId}/${questionId}`,
      reqData,
      { params: query },
    );
    return result;
  }

  async uploadQuestions(
    appId: string,
    courseId: string,
    subjectId: string,
    questions: QuestionForm[],
  ): Promise<{
    numberOfSuccess?: number;
    questions?: Array<ImportQuestionResult>;
    succededQuestions?: Array<ImportQuestionResult>;
    message?: Lang;
  }> {
    const result: {
      numberOfSuccess?: number;
      questions?: Array<ImportQuestionResult>;
      succededQuestions?: Array<ImportQuestionResult>;
    } = await provider.post(
      `/api/questions/${appId}/${courseId}/uploadQuestions`,
      { questions, subjectId },
    );
    return result;
  }
}
export default new QuestionsProvider();

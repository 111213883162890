






import { Component, Vue, Provide } from 'vue-property-decorator';
import QuestionProvider from '@/providers/questions.provider';
import CourseList from '../../shared/components/CourseList.component.vue';
import { APPIDS, EXAM_GENERATION_ROUTE_NAME } from '../../enums';

@Component({
  components: { CourseList },
})
export default class ExamGrenerationCourseList extends Vue {
  @Provide() Provider = QuestionProvider;

  appId = APPIDS.EXAM_GENERATION;

  name = 'ExamGrenerationCourseList';

  goToGeneratedExamsList(courseData) {
    this.$router.push({
      name: EXAM_GENERATION_ROUTE_NAME.EXAMS_LIST,
      params: {
        courseId: courseData.courseId.toString(),
      },
    });
  }
}
